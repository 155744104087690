<template lang="html">
  <div class="wangeditor" >
      <div ref="toolbar" class="toolbar" :style="{width:width}"></div>
      <div ref="wangeditor" class="text"  :style="style"></div>
  </div>
</template>
 
<script>
import E from "wangeditor";
export default {
  data() {
    return {
      wangEditor: null,
      wangEditorInfo: null,
      style:{
        width:this.width,
        height:this.height
      },
        upload_qiniu_addr: 'http://img.curiousmore.com/'
    };
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    isClear: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '500px'
    }
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.wangEditor.txt.clear();
        this.wangEditorInfo = null;
      }
    },
    value: function(value) {
      if (value !== this.wangEditor.txt.html()) {
        this.isClear = false;
        this.wangEditor.txt.html(this.value); //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
      }
    }
  },
  mounted() {
    this.initEditor();
    this.wangEditor.txt.html(this.value);
  },
  methods: {
    initEditor() {
        let _this = this
      this.wangEditor = new E(this.$refs.toolbar, this.$refs.wangeditor);
      this.wangEditor.config.uploadImgShowBase64 = false; // base64存储图片（推荐）
    //   this.wangEditor.config.uploadImgServer = "http://up-z0.qiniup.com/"; // 配置服务器端地址（不推荐）
    //   this.wangEditor.config.uploadImgHeaders = {}; // 自定义header
    //   this.wangEditor.config.uploadFileName = "file"; // 后端接受上传文件的参数名
      this.wangEditor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 将图片大小限制为（默认最大支持2M）
      this.wangEditor.config.uploadImgMaxLength = 6; // 限制一次最多上传6张图片
      this.wangEditor.config.uploadImgTimeout = 1 * 60 * 1000; // 设置超时时间（默认1分钟）
      
      // 配置菜单
      this.wangEditor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
        "fullscreen" // 全屏
      ];
     
      this.wangEditor.config. customUploadImg = function (resultFiles, insertImgFn) {
        console.log("resultFiles", resultFiles);
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        //有可能会上传多张图片,上传多张图片就需要进行遍历
        resultFiles.map((item) => {
            // _this.getUploadImg(item, insertImgFn);
            _this.getUploadFile(item, insertImgFn);
        });
        };
      this.wangEditor.config.onchange = html => {
        this.wangEditorInfo = html;
        this.$emit("change", this.wangEditorInfo); // 将内容同步到父组件中
      };
      // 创建富文本编辑器
      this.wangEditor.create();
    },
    getUploadFile(item, insertImgFn) {
        console.log(item);
      let _this = this
      var timestamp = new Date().getTime()
      let data = {
        key: timestamp+'.png',
        type: 'pic',
      }
      _this
        .$axios({
          method: 'post',
          url: 'https://www.curiousmore.com/qmore/push/upload/getToken',
          data: data,
        })
        .then((res) => {
        console.log(item);
        var formData = new FormData();
         formData.append("file", item);
         formData.append("token", res.data.data);
         formData.append("key", data.key);
            console.log(data);
            _this.$axios({
            method: 'post',
            url: 'http://up-z0.qiniup.com',
            data:formData
            })
            .then((res) => {
                console.log(res);
                insertImgFn( this.upload_qiniu_addr+data.key)
               
            })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  }
};
</script>
 
<style lang="less">
.wangeditor {
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  .toolbar {
    border-bottom: 1px solid #e6e6e6;
    box-sizing: border-box;
  }
  .text {
    min-height: 300px;
  }
  // .w-e-toolbar{
  //  z-index: 100 !important;
  // }
  // .w-e-text-container{
  //   z-index: 100 !important;
  // }
}
</style>